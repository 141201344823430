@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/**************************[ BASE ]**************************/
body {
	font-family: 'FranklinGothicBook', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #333;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 16px;
	line-height: 24px;
}
h1 {
	font-size:32px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'GOUDOS', sans-serif;
	font-weight:bold;
	color:#333;
}
h2 {
	font-size:28px;
	font-weight:bold;
	letter-spacing:0;
	text-transform: none !important;
	line-height:28px;
	font-family: 'GOUDOS', sans-serif;
}
h3 {
	font-size:20px;
	font-weight:bold;
	line-height:inherit;
	font-family: 'GOUDOS', sans-serif;
    text-transform:uppercase;
}
h4 {
	font-weight:bold;
	font-size:16px;
	font-family: 'GOUDOS', sans-serif;
}
h6 {
	font-size:24px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'GOUDOS', sans-serif;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-list .product-name, .section-title, h1, h2, h3, h3.product-name, h4, h5
{
	font-family: 'GOUDOS', sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#333;
}

/**************************[ HEADER ]**************************/
.header a, .header p, .header span {
	font-size: 16px;
	color: #fff;
	font-family: 'FranklinGothicBook', sans-serif;
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}
.header-top-container {
	background-color:#333;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg *, .userCode, .userName {
	color: #fff !important;
}

/* Logo */
.logo img {
	max-width: 250px;
}

/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #777;
	font-family: 'FranklinGothicBook', sans-serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #777;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 14px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #333;
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value {
	color: #333;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #fff;
	border-width: 6px 5px 0;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 5px;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	top: 8px;
	left: -15px;
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}

/* Menu */
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #777;
	font-size: 16px;
	font-family: 'GOUDOS', sans-serif;
}
.links > li > a {
    font-family: 'FranklinGothicBook', sans-serif;
}

.links > li > a:hover, .userSwitch a:hover {
	background-color: #333333;
	color: #ffffff !important;
}

#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	background-color: #fff;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #333;
}
.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 5px solid #333;
}
#nav .nav-panel--dropdown a {
	color: #333;
	font-size: 16px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 16px;
}
#nav .nav-panel--dropdown a:hover {
	color: #333;
}
.mobnav-trigger.active {
	background-color: #333;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #333;
}
#nav .nav-item--home .fa-home {
	color: #333 !important;
	padding: 0.3rem;
}
#root-wrapper a:hover span.fa:before {
	color: #333;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #333;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #333;
	border-right-color: #333;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #fff !important;
	padding: 0;
	border-radius: 0;
	max-height: 35px;
	font-size: 16px;
	margin-top: 10px; color:#333;
}
#mini-cart .actions button.button span span:hover {
	background-color: #8D2B2F !important; 
    color:#fff !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}

/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 11px;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 32px;
	color: #333;
	letter-spacing: 3px;
	text-transform: uppercase;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #333;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #fff;
	border-radius: 0;
	padding: 0;
}
button, .button, .button a, button a {
	font-family: 'GOUDOS', sans-serif;
	font-size: 16px;
}
button.button span {
	font-size: 16px;
}
button.button span span {
	padding: 0 10px; min-width:100px !important; height: 35px;
    line-height: 35px;
    border: 1px solid #333;
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: #8D2B2F !important;
}
.button:hover span span {
    border: 1px solid #8D2B2F;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 14px;
	color: #333;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #333;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #f5f5f5;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

/**************************[ Footer ]**************************/
.footer-container {
	background-color: #ddd;
}
.footer-container, .footer-container2, .footer-primary.footer.container, .footer-top
{
	background-color: #CABFBA;
}
.footer-top-container {
	padding-top: 10px;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer-bottom-container.section-container {
	background-color: #333;
	color: #999; display:none;
}


/************************** MICS **************************/

/**Footer**/
@media only screen and (min-width:480px) {
     .footer-top-container .section {
         width:100%;
         display:flex;
    }
     .footer-top-container .section > div {
         margin:auto;
    }
     .footer-links-column {
         text-align:right;
    }
}
@media only screen and (max-width:479px) {
     .footer-links-column {
         margin-top:15px;
    }
}
#footer .footer-links-column a {
    font-family: 'GOUDOS', sans-serif !important;
}

/*Header*/
.links>li>a {
     color:#FFF;
}
.header-top-container {
     background-color:#333;
     box-shadow:none;
}
.xm-grid-header .top-header-container {
     background-color:#333;
}
.header-top-container .module-header-multi, .header-top-container .module-header-multi .item-welcome-msg {
     margin-left:0;
}
.xm-grid-header .top-header-container .nested-container, .desktop-top-header .nested-container {
     margin:0 auto;
}
/*Align the text on the top header*/
.top-header-container .module-header-multi span, .top-header-container .module-header-multi a {
     line-height:3em;
}
.top-header-container .module-header-multi p {
     margin-bottom:0;
     line-height:3em;
}
/*Menu*/
.desktop-top-header .module-category-menu .nav-item.nav-item--home {
     display:none;
}
.desktop-top-header .grid-container, .desktop-top-header ul#nav {
     display:flex;
}
.desktop-top-header .grid-container > div {
     margin:auto;
}
.desktop-top-header ul#nav > li.level0 {
     margin:0 auto;
}
.nav-regular li.level0>a>span {
     color:#333;
    font-size: 22px;
}
#nav .nav-panel--dropdown a>span {
    font-size: 20px;
    font-family: 'GOUDOS', sans-serif;
}
/*search*/
.module-search .form-search .button-search .fa, .module-search .form-search input::placeholder {
     color:#333 !important;
}
/*cart*/
.desktop-top-header #mini-cart .hide-below-960 .fa-shopping-cart {
     display:none;
}
/*Form elements site-wide*/
input, select, textarea {
     border:1px solid #333 !important;
}
@media only screen and (max-width:959px) {
     .hide-below-960 {
         display:none !important;
    }
     #mini-cart .hide-below-960 {
         display:inline-block !important;
    }
}
@media only screen and (min-width:960px) {
     .hide-above-960 {
         display:none !important;
    }
}
@media only screen and (min-width:768px) {
     .hide-above-768 {
         display:none !important;
    }
}
@media only screen and (max-width:767px) {
     .hide-below-768 {
         display:none !important;
    }
}
/*Change the account menu icon for mobile/tablet*/
.fa-vcard:before{
     content:"" !important;
}
.fa-vcard:after{
     content:"\f007";
     color:#fff;
}
/*Change the text 'Switch Customer' to a FA icon for small width devices.*/
@media only screen and (max-width:959px) {
     .userSwitch a {
         text-indent:-9999px;
         position:absolute;
    }
     .userSwitch a::after {
         content:"\f2c3";
    }
     .userSwitch a::after {
         font-family:fontAwesome;
         font-size:14px;
         text-indent:0;
         float:left;
    }
}
/*Header for Tablet*/
@media only screen and (min-width:768px) and (max-width:959px) {
     .desktop-top-header .category-column {
         display:none;
    }
     .desktop-top-header .logo-column {
         width:39%;
    }
     .desktop-top-header .search-column {
         width:31%;
    }
     .desktop-top-header .cart-column {
         width:23%;
    }
     .desktop-top-header, .desktop-top-header .search-column {
         overflow:unset;
    }
     /*Align category bars with the account icon*/
     .fa.fa-bars.userCode {
         vertical-align:inherit;
    }
}
/*Header for Mobile*/
@media only screen and (max-width:767px){
     .mobile-header, .mobile-logo-img p{
         margin-bottom:0;
    }
     .mobile-header .header-top-container {
         background-color:#CABFBA;
    }
     .mobile-header .grid-container {
         display:flex;
    }
     .mobile-header .grid-container > div {
         margin:auto;
    }
     .mobile-header #mini-cart .cart-total, .mobile-header #mini-cart .empty {
         display:none;
    }
     .mobile-cart-menu .page-content {
         margin-top:15px;
    }
     .fa:not(.fa-angle-left):not(.fa-angle-right), .fa-vcard:after {
         font-size:22px !important;
         color:#333 !important;
    }
     .mobile-search, .mobile-search .grid-column-wrapper {
         overflow:unset;
    }
     .mobile-header .module-search .form-search .input-text {
         display:none;
    }
     .mobile-header .module-search .button.button-search {
         position:unset!important;
    }
     .mobile-logo-img {
         text-align:center;
    }
     .mobile-cart-menu .module-header-multi {
         display:flex;
    }
     .mobile-cart-menu .module-header-multi > div.item-search {
         margin:auto;
    }
     /*Search*/
     .mobile-header .item-search.fly-out {
         position:fixed;
         display:block!important;
         top:0;
         right:0;
         left:0;
         bottom:0;
         z-index:9999;
         width:100%!important;
         background-color:#fff;
         margin-left:0;
    }
     .mobile-header .item-search.fly-out .form-search .button {
         right:10px;
         position:absolute!important;
         margin:0;
         padding:0;
         font-size:inherit;
    }
     .mobile-header .item-search.fly-out .form-search .button-search {
         right:45px;
    }
     .mobile-header .form-search .button-close .fa {
         font-size:16px;
         color:#333;
         padding:10px;
         font-style:normal;
    }
}
/*Sticky header*/
#header {
     position:sticky;
     top:0;
     z-index:50;
}
/*Set all fa icons to be normal style*/
.std i {
     font-style:normal;
}
/*Inline search results*/
 #inline-search-results {
     right:0;
     left:initial;
}
@media only screen and (min-width:960px) {
     #inline-search-results {
         width:300%;
         right:0;
         left:initial;
    }
     #inline-search-results .grid12-8.results-right {
         width:68%;
    }
     #inline-search-results li.grow {
         width:45%;
    }
}
@media only screen and (max-width:768px) {
     #inline-search-results {
         width:96%;
         left:0 !important;
         margin:auto;
    }
}

/*Category pages*/
.product-listing, .sidebar-section {
     margin-top:0 !important;
}
.heading {
     text-align:center;
}
/*Breadcrumbs*/
#breadcrumbs li span{
     text-transform:capitalize;
     color:#333;
}
.product-search-container {
     margin-left:0;
}
.product-search-container .sidebar-category .block-title span, .product-search-container .category-title h1{
     padding:0 !important;
}
/*Pagination*/
.pager {
     border:none;
     margin:0;
     padding:0;
}
.pagination .module-category-misc {
     text-align:right;
}
.pager li a, .pager strong{
     font-size:14px;
}
.pager .pages li a{
     background-color:#CABFBA;
}
.pager .pages li.current {
     background-color:#fff;
}
.pager .pages li a:hover {
     background-color:#CABFBA;
}
/*Sidebar*/
.product-search-container .block-title {
     display:none;
}
.product-search-container .block-content .accordion-style1 li a {
     font-size:20px !important;
     border-bottom:none;
}
/*Listing*/
.category-products-grid .item {
     border:none;
     margin-bottom:70px;
}
 .item .module-category-product-listing span, .module-category-product-listing li, .module-category-product-listing li strong, .item .module-category-product-listing .product-name a {
     font-size:18px !important;
}
.item .module-category-product-listing h2.product-name {
     margin-bottom:15px;
}
.item .module-category-product-listing h2.product-name a {
     color:#000;
}
.item .module-category-product-listing .desc.std {
     margin-top:15px;
     margin-bottom:15px;
     font-size:18px;
}
.item .module-category-product-listing .product-image-wrapper.product-description-wrapper {
     margin-bottom:30px;
}
.item .module-category-product-listing .product-description-wrapper .product-shop-inner {
     min-height:60px;
}
.item .module-category-product-listing .price-box {
     margin-top:15px;
}
@media only screen and (min-width:768px) {
     .item .module-category-product-listing .add-cart-wrapper {
         display:flex;
         justify-content:center;
    }
     .item .module-category-product-listing .add-cart-wrapper > .qty-wrapper{
         margin:auto 10px;
    }
     .item .module-category-product-listing .add-cart-wrapper > button{
         margin:auto;
         margin-left:10px;
    }
}
.item .module-category-product-listing .add-cart-wrapper input.qty {
     height:34px;
}
@media only screen and (max-width:959px) {
     .item .module-category-product-listing .grid12-12 {
         overflow-y:hidden;
    }
     .item .module-category-product-listing .item-code-label {
         display:none;
    }
}
@media only screen and (max-width:767px) {
     .products-grid .item button.btn-cart span span {
         padding:0 12px !important;
    }
}
/*Hover effect*/
.category-products-grid .item:hover {
     z-index:10;
     box-shadow:0 0 20px rgba(0,0,0,.2);
     border-color:transparent;
     transition:box-shadow .4s ease-out;
     -moz-transition:box-shadow .4s ease-out;
     -webkit-transition:box-shadow .4s ease-out;
     -o-transition:box-shadow .4s ease-out;
}
/*Expand the width for small width devices*/
@media only screen and (max-width:959px) {
     .product-listing {
         width:98% !important;
    }
}
/*Category pages:end*/
/**/
.account-view .block-content.mm-menu ul.xm-mega-menu > li {
     width:100%;
}
/*Bring the progress bar to align with the text box*/
.progress, div[class^=strength_meter_passwd] {
     top:25px !important;
}
/*Order page*/
#order-table{
     margin-top:20px;
}
.buttons-set.module-order-details-actions {
     text-align:end;
}
.buttons-set.module-order-details-actions > div:hover {
     border-bottom:1px solid #333;
}
/*Set min height for the addresses columns*/
@media only screen and (min-width:960px) {
     .order-info-box.module-order-details-billing, .order-info-box.module-order-details-shipping, .col-1.addresses-primary .item, .col-2.addresses-primary .item{
         min-height:278px;
    }
     /*Align the misc info with billing data*/
     .module-order-details-misc > div {
         padding-left:10px;
    }
}
/*Expand the billing and shipping addresses for tablet devices*/
@media only screen and (min-width:768px) and (max-width:959px) {
     .billing-module, .shipping-module, .col-1.addresses-primary, .col-2.addresses-primary {
         width:99%;
    }
     .col-2.addresses-primary {
         float:left;
    }
}
/*Transaction page - Change the Pay Open Invoice link to button*/
.account-view a.button span span, .module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie {
     padding:0 10px;
     min-width:100px!important;
     height:35px;
     line-height:35px;
     border:1px solid #333;
     display:inline-block;
}
.account-view a.button span, .module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie {
     font-size:16px;
     margin:0;
     background-color:#fff;
     border-radius:0;
     padding:0;
     cursor:pointer;
}
.btn.btn-outline-secondary.btn-pay-invocie:hover {
     background-color:#8d2b2f;
     color:#fff;
     border-color:#8d2b2f;
}
.account-view a.button:hover span {
     color:#fff;
}
#transhistory-table {
     margin-top:20px;
}
/*Prevent scrollbars*/
@media only screen and (min-width:768px) and (max-width:959px) {
     .account-view .xm-grid-account .grid-container-wrapper {
         overflow:unset;
    }
     .billing-module, .shipping-module{
         width:99%;
    }
}
/*Adjust the size of form elements for small devices*/
@media only screen and (max-width:959px) {
     .ReactTable .rt-thead.-filters input{
         max-width:80px;
    }
     .ReactTable .rt-thead.-filters select{
         width:80%;
         height:26px;
    }
     .ReactTable a, .ReactTable div, .ReactTable p, .ReactTable span{
         font-size:11px !important;
    }
     /*Display block the links in the last column*/
     #order-table .ReactTable .rt-tbody .rt-tr .rt-td:last-child a{
         display:block;
         width:90px;
         text-align:center;
    }
     #order-table .ReactTable .rt-tbody .rt-tr .rt-td:last-child span {
         display:none;
    }
     .module-account-transhistory-content-placeholder .a-right {
         text-align:left !important;
    }
     #order-exportbutton .button.right {
         float:left !important;
    }
     .ReactTable .fa-calendar:before {
         font-size:14px;
    }
     .order-items, .module-order-details-misc .order-details{
         overflow-x:hidden !important;
    }
}
/*Login form*/
/*Add bottom margin in between form fields*/
.module-login-form.registered-users .form-list li {
     margin-bottom:15px;
}
/*For tablet devices, expand the width of the login page*/
@media only screen and (min-width:768px) and (max-width:959px) {
     .new-users.grid12-6 {
         width:96%;
         margin-bottom:40px;
    }
}
.page-title.module-trans-details-status h1 span {
     font-size:32px;
}
.module-trans-details-misc .item-options {
     display:flex;
}
.module-trans-details-misc .item-options > * {
     margin:auto 0;
}
.module-trans-details-actions input.form-control {
     height:30px;
     width:70px;
}
@media only screen and (max-width:1199px) {
     .xm-cms-user-account.account-view #top .main.container {
         width:94% !important;
         max-width:98% !important;
    }
     .account-view .xm-grid-account .grid-group-1 .grid-container-wrapper.grid12-10 {
         width:98%;
    }
}
.module-order-details-actions p.back-link .fa.fa-angle-double-left:before {
     display:none;
}
@media print {
     .ord-details .grid-column-wrapper {
         width:98%;
    }
     .module-trans-details-listing {
         max-width:95%;
    }
     .module-order-details-listing {
         max-width:95%;
    }
     @page {
         size:landscape 
    }
}

/**/
#shopping-cart-table h2.product-name span {
     font-size:30px;
}
#shopping-cart-table h2.product-name span.item-code {
     display:none;
}
.cart-action-buttons button {
     margin:20px 10px;
}
@media only screen and (max-width:767px) {
     #shopping-cart-table {
         overflow:hidden;
    }
}
@media only screen and (max-width:959px) {
     .cart .main.container {
         width:90% !important;
         max-width:90% !important;
    }
     .cart-summary-col{
         width:55%;
    }
}
@media only screen and (min-width:1200px) {
     .cart-summary-col{
         width:30%;
    }
     .update-empty-cart {
         width:66%;
    }
}
body.cart .data-table:not(.cart-table) .col-img img {
     width:60%;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .opc-main-container.onepage-checkout-main-container, .in-sidebar.opc-progress-container {
         width:98%;
    }
}
@media only screen and (max-width:767px) {
     body.cart .data-table tfoot td {
         display:inline-block !important;
         width:30% !important;
         min-width:100px;
         max-width:140px;
         margin-right:10px;
         font-weight:700;
    }
}
